.map {
  // position: fixed;
  // top: 70px;
  // max-width: 50%;
  // min-height: 100%;
  // left: 80rem;
  // background-attachment: fixed;
  // top: 70px;
  // width: 100%;
  position: fixed;
  padding-left: 40%;
  // margin-bottom: 10%;
  // top: -10px;
  // position: absolute;
  // height: 100%;
  // background: green;
  // left: 44%;
  
  .popup{
    h3{
      font-size: 16px;
      text-align: center;

    }
    h5{
      font-size: 14px;
      font-weight: 500;
      text-align:center;
    }
  }

  .circle-high-index, .circle-low-index, .circle-highest-index {
    img {
      width: 40px;
      height: 40px;
      cursor: pointer;
    }
    span {
      cursor: pointer;
      display: block;
      margin-top: -40px;
      padding-left: 0.73rem;
      font-size: 20px;
      color: white;
      font-family: 'Open Sans', sans-serif;
      font-weight: 400;
    }
  }
  .circle-low-index {
    span {
      padding-left: .9rem;
      padding-top: .1rem;
      font-size: 20px;
    }
  }
  .circle-high-index {
    span {
      padding-left: 0.58rem;
      padding-top: .2rem;
      font-size: 20px;
    }
  }
  .circle-highest-index {
    span {
      padding-left: 0.6rem;
      padding-top: .2rem;
      font-size: 18px;
    }
  }
}

.current-location {
  img {
    width: 3rem;
    height: 3rem;
  }
  h3{
    font-size: 17px;
  }
}
