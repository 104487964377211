header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 65px;
  margin: 0;
  background-color: #f3f2f2;
  position: fixed;
  z-index: 80;
}

@media (max-width: 1000px) {
  header {
    height: 73px;
  }
}

.logo {
  display: flex;
  align-items: center;
  padding-left: 20px;
  // img {
  //   width: 70px;
  //   white-space: nowrap;
  //   overflow: hidden;
  // }
  p {
    font-weight: bold;
    color: #6a149b;
    font-size: 1.25rem;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
  }
}

@media (max-width: 1000px) {
  .logo {
    p{
      display: none;
    }
    // display: none;
  }
}



.nav-input {
  margin-left: -13rem;
  .nav-form {
    // width: 100vw;
    input {
      // width: 30%;
      // min-width: 300px;
      // max-width: 600px;
      font-size: 1.5rem;
      border-radius: 10px;
      height: 30px;
      border: 0;
      font-weight: lighter;
      box-shadow: 0px 0px 10px rgba(171, 168, 172, 0.5);
      color: rgb(82, 80, 80);
      outline: none;
      white-space: nowrap;
      overflow: hidden;
      &::-webkit-input-placeholder {
        font-size: 22px;
        font-family: "Questrial";
      }
      &[type="text"] {
        text-indent: 11px;
        font-family: "Questrial";
      }
    }
    button {
      background: white;
      border: 0;
      border-radius: 10px;
      height: 30px;
      width: 48px;
      margin-left: 1rem;
      padding-top: .12rem;
      // margin-bottom: 1rem;
      // white-space: nowrap;
      cursor: pointer;
      color: rgb(82, 80, 80);
      &:hover {
        color: #6a149b;
      }
      &:focus {
        outline: none;
      }
      box-shadow: 0px 0px 14px rgba(171, 168, 172, 0.5);
    }
  }
}


@media (max-width: 1000px) {
  .nav-input {
    margin-left: 0;
    // display: none
    .nav-form{
      width: 100vw;
    }
  }
}

nav {
  ul {
    display: flex;
    align-items: center;
    li {
      list-style: none; // no dots near list style
      &:last-child {
        padding-right: 40px;
      }
      a {
        text-decoration: none; // not highlighted blue
        color: #6a149b;
        padding: 22px; ///space between the two Links
        font-size: 1rem;
        // white-space: nowrap;
        // overflow: hidden;
      }
    }
  }
}

@media (max-width: 1000px) {
  nav {
    ul {
      li{
        a{
          font-size: 1rem;
          padding: 10px;
        }
      }
    }
  }
}