.results-title-testing,
.results-title-vaccine {
  width: 40%;
  height: 9.5rem;
  position: fixed;
  z-index: 50;
  h1 {
    padding: 6.05rem 0rem 2.2rem 1.5rem;
    border-radius: 2.5px;
    font-size: 1.23rem;
    color: white;
  }
}

.results-title-testing {
  background: rgb(131, 58, 180);
  background: linear-gradient(
    90deg,
    rgba(131, 58, 180, 1) 0%,
    rgba(91, 27, 138, 1) 50%
  );
}

.results-title-vaccine {
  background: rgb(131, 58, 180);
  background: linear-gradient(
    90deg,
    rgba(131, 58, 180, 1) 0%,
    rgba(91, 27, 138, 1) 50%
  );
}

@media (max-width: 1100px) {
  .results-title-testing {
    width: 100%;
    h1 {
      margin-top: -0.6rem;
    }
  }
  .results-title-vaccine {
    width: 100%;
    h1 {
      margin-top: -0.6rem;
      padding-right: 2rem;
    }
  }
}

.results-description {
  width: 40vw;
  background: rgb(246, 236, 246);
  border-bottom: 1px solid #f5d8fa;
  margin-top: -1rem;
  h3 {
    padding: 1.25rem 1.5rem 1.25rem 1.5rem;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    font-size: 15px;
    a {
      text-decoration: none;
      color: #6a149b;
    }
  }
}

@media (max-width: 1100px) {
  .results-description {
    width: 100vw;
    h3 {
      max-width: 100vw;
      padding: 1.15rem;
    }
  }
}
.map {
  color: #6a149b;
}

@media (max-width: 1100px) {
  .map {
    display: none !important;
  }
}
.source {
  padding-top: 1rem;
  padding-bottom: 1rem;
  h3 {
    text-align: center;
    a {
      text-decoration: none;
      color: #6a149b;
    }
  }
}
.list-map {
  display: flex;
}

.searched-centers-testing {
  margin-top: 9.3rem;
  position: absolute;
  z-index: 40;
  background: #ffffff;
}

.searched-centers-vaccine {
  margin-top: 10.5rem;
  position: absolute;
  z-index: 40;
  background: #ffffff;
}

.testing-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #f6f6f4;
  cursor: pointer;
  &:hover {
    background: rgb(235, 233, 235);
  }
  border-radius: 3px;
  width: 40vw;
  padding: 1rem 0rem 0rem 0rem;
  h1 {
    width: 70%;
    padding-left: 1.5rem;
    font-size: 24px;
  }
  .testing-info {
    h4 {
      padding-top: 1.5px;
      color: #6a149b;
      padding-left: 1.5rem;
    }
    h3 {
      padding-top: 9px;
      padding-bottom: 10px;
      padding-left: 1.5rem;
      font-weight: 400;
      font-size: 16px;
    }
  }
  .testing-buttons {
    z-index: 10;
    display: flex;
    padding-top: 0.7rem;
    padding-bottom: 1.7rem;
    padding-left: 1.5rem;
    button {
      margin-right: 3rem;
      background-color: #f6f6f4;
      color: rgb(54, 54, 54);
      border: 1.2px solid #6a149b;
      border-radius: 4px;
      width: 7.5rem;
      height: 25px;
      font-size: 14px;
      font-family: "Questrial";
      &:focus {
        outline: none;
      }
      font-family: sans-serif;
      cursor: pointer;
      a {
        color: rgb(54, 54, 54);
        text-decoration: none;
      }
      &:hover {
        background: rgb(131, 58, 180);
        background: linear-gradient(
          90deg,
          rgba(131, 58, 180, 1) 0%,
          rgba(91, 27, 138, 1) 50%
        );
        color: white;
        a {
          color: white;
        }
      }
    }
  }
}

@media (max-width: 1100px) {
  .testing-card {
    width: 100vw;
    &:hover {
      background: #f6f6f4;
    }
    h1 {
      width: 97%;
    }
    .testing-buttons {
      button {
        width: 12rem;
        height: 2.1rem;
      }
    }
  }
}

.age {
  padding-bottom: 0.3rem;
  padding-top: 0.3rem;
  display: flex;
  margin-left: 1.5rem;
  h4 {
    padding-left: 0.4rem;
    color: rgb(54, 54, 54);
  }
}

