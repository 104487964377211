* {
  //gets rid of all initial/default padding margin
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: subpixel-antialiased;
  text-rendering: optimizeLegibility;
  // font-feature-settings: "kern";
}
h1,
h2,
h3,
h4 {
  color: rgb(54, 54, 54);
  text-rendering: optimizeLegibility;
  // font-feature-settings: "kern";
}

body {
  font-family: "IBM Plex Serif", sans-serif;
  -webkit-font-smoothing: subpixel-antialiased;
  // -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  // font-feature-settings: "kern";
}
//center spinner in middle of the screen/page
.spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@import "./Testing/home";
@import "./nav";
@import "./Testing/searched";
@import "./Testing/map";
@import "./other_pages";
