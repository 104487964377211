.error,
.contact {
  h1 {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10rem;
  }
  h3 {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 1rem;
    a{
      text-decoration: none;
      color: #6a149b;
    }
  }
  h2 {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 3rem;
  }
}

