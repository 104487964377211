.home-title {
  h1 {
    font-weight: bold;
    font-size: 35px;
    margin-top: 11rem;
    color: white;
    // white-space: nowrap;
    // overflow: hidden;
  }
}

@media (max-width: 1100px) {
  .home-title {
    h1{
      font-size: 35px;
      text-align: center;
    }
  }
}


.home-search-full,
.home-search-full-vaccine {

  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgb(131, 58, 180);
  background: linear-gradient(
    90deg,
    rgba(131, 58, 180, 1) 0%,
    rgba(91, 27, 138, 1) 50%
  );
  height: 100vh;
  max-width: 100vw;
  
}

.home-search-full {
  background: rgb(131, 58, 180);
  background: linear-gradient(
    90deg,
    rgba(131, 58, 180, 1) 0%,
    rgba(91, 27, 138, 1) 50%
  );
}

.home-search-full-vaccine {
  background: #6c34b1;
  background: -webkit-linear-gradient(top left, #6c34b1, #562a63);
  background: -moz-linear-gradient(top left, #6c34b1, #562a63);
  background: linear-gradient(to bottom right, #6c34b1, #562a63);
}

.home-search {
  margin-top: 4rem;
  img{
    width: 95px;
    height: 170px
  }
  h2 {
    color: white;
    padding-bottom: 1rem;
    font-size: 20px;
  }
  text-align: center;
  .search {
    // this is the form for the button searching
    padding: 4rem;
    // background: pink;
    display: flex;
    text-align: center;
    input {
      // background: orange;
      width: 250px;
      // align-items: center;
      font-size: 2.3rem;
      height: 55px;
      &[type="text"] {
        text-indent: 12px;
        font-family: "Questrial";
      }
      // &::placeholder{
      // padding-left: 10px;
      // }
      // min-width: 100px;
      margin: 1rem;
      background: #f3f2f2;
      margin-right: 10px;
      border: none;
      font-family: Arial, Helvetica, sans-serif;
      font-weight: lighter;
      // border-radius: 1rem;
      box-shadow: 0px 0px 20px rgba(253, 253, 253, 0.5);
      outline: none;
      &::-webkit-input-placeholder {
        font-size: 30px;
        font-family: "Questrial";
      }
      color: rgb(82, 80, 80);
      border-radius: 4px;
      // overflow: hidden;
      padding-left: 2.5px;
    }
    button {
      background: #f3f2f2;
      box-shadow: 0px 0px 20px rgba(253, 253, 253, 0.5);
      height: 55px;
      width: 100px;
      margin-top: 16px;
      border: none;
      cursor: pointer;
      border-radius: 3px;
      &:hover {
        background-color: rgba(225, 227, 228, 0.986);
      }
      &:focus {
        outline: none;
      }
    }
  }
}

@media (max-width: 1100px) {
  .home-search {
    img{
      margin-top: 4rem;
    }
    max-width: 100vw;
    h2{
      padding-right: 25px;
      padding-left: 25px;
      font-size: 15.5px;
      text-align: center;
      word-wrap: break-word;
      // padding-top: 35px;
    }
    .search {
      padding: 0px;
      margin-top: 2.3rem;
      input{
        height: 50px;
        width: 240px;
      }
      button{
        height: 50px;
        width: 90px;
      }
  }
}
}

